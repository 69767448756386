<template>
  <div class="app-container">
    <van-cell title="授权公众号" is-link value="点这里" @click="linkToAuth" />
  </div>
</template>
<script>
import { Cell } from 'vant'
import { getPreAuthCodeApi } from '@/api/wechat'
export default {
  components: { VanCell: Cell },
  data () {
    return {
      brandCode: ''
    }
  },
  created () {
    this.brandCode = this.$route.query.brandCode
  },
  methods: {
    linkToAuth () {
      if (!this.brandCode) {
        this.$toast('请先选择品牌')
        return false
      }
      getPreAuthCodeApi().then((res) => {
        if (!res.data.preAuthCode) {
          return false
        }
        const componentAppId = res.data.componentAppId
        const preAuthCode = res.data.preAuthCode
        const redirectUri = encodeURIComponent(`${process.env.VUE_APP_POS_URL}/#/wechat/auth/success?brandCode=${this.brandCode}`)
        const url = `https://mp.weixin.qq.com/cgi-bin/componentloginpage?component_appid=${componentAppId}&pre_auth_code=${preAuthCode}&redirect_uri=${redirectUri}&auth_type=3`
        location.href = url
      })
    }
  }
}
</script>
